const data = {
  "items": [
    {
      "id": 0,
      "heading": "Delivering Software with Quality and Security",
      "text": ""
    },
    {
      "id": 1,
      "heading": "Our Services",
      "text": " We offer a wide range of solutions from Software as a Service (SaaS) to different Hosting Schemes, we provide IT Services like Web Development, Mobile Applications, ERPs, Digital Marketing and Branding, Strategy Formulation, 2D/3D Animation, Graphic Design, Google Analytics and much more. We are in pace with the ever changing technology and keep up to the industrial standards.",
    },
    {
      "id": 2,
      "heading": "About Us",
      "text": "We are dedicated to providing our customers with secure, high quality products built with technology that is simple and easy to use yet powerful and impactful. Our approach is committed, straightforward and personal."
    }
  ]
}


export default data;