import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import logo from '../logo_old.png';
import ContactModal from './contactModal.js'
import CareerModal from './careerModal';
import PrivacyPolicy from './PrivacyPolicy';
import TnC from './TnC.js'


export default class NavbarCustom extends Component {

    render() {
        return (
            <Navbar collapseOnSelect expand="md" variant="dark">
                <Navbar.Brand href="/" className="pt-0 pb-0 pl-2">
                    <img
                        src={logo}
                        width="35"
                        height="35"
                        className="d-inline-block align-top p-0"
                        alt="RonLi-Logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" className="p-0 m-0" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="pl-4 " activeKey>
                        <Nav.Link className="nav-link-main" href="#" onClick={() => this.props.onClick(2)}>About</Nav.Link>
                        <Nav.Link className="nav-link-main" href="#" onClick={() => this.props.onClick(1)} > Services</Nav.Link>
                        <Nav.Link className="nav-link-main" href="#"><ContactModal /></Nav.Link>
                        <NavDropdown className="nav-link-main " title="More" id="collasible-nav-dropdown">
                            <NavDropdown.Item href="#"><CareerModal /></NavDropdown.Item>
                            <NavDropdown.Item href="#"><PrivacyPolicy /></NavDropdown.Item>
                            <NavDropdown.Item href="#"><TnC /></NavDropdown.Item>
                            {/* <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}
