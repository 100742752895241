import React, { useState, useEffect } from 'react';
import '../App.scss'
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { withFormik, Field, Form } from 'formik';
// import { ReCAPTCHA } from "react-google-recaptcha";
// import * as yup from 'yup';
import { MdLocationOn, MdEmail } from 'react-icons/md'
import world_map from '../world_map.svg';
import cogoToast from "cogo-toast";
import axios from 'axios';
require("react-bootstrap/ModalHeader");

const MyForm = ({ values, isSubmitting, handleSubmit, setFieldValue }) => {
    const [show, setShow] = useState(false);
    const [token, setToken] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // const schema = yup.object({
    //     firstName: yup.string().required(),
    //     lastName: yup.string().required(),
    //     username: yup.string().required(),
    //     city: yup.string().required(),
    //     state: yup.string().required(),
    //     zip: yup.string().required(),
    //     terms: yup.bool().required(),
    // });

    useEffect(() => {
        const grecaptcha = window.grecaptcha
        grecaptcha.ready(function () {
            grecaptcha.execute('6LczQrgZAAAAAKlY8s2sNZa8wKCTuxd55RZEE7L_', {
                action: 'homepage'
            }).then(function (token) {
                setToken(token);
            });
        });
    }, []);

    const handleSubmiting = () => {
        values.reToken = token
        handleSubmit()
    }

    return (
        <>
            <span onClick={handleShow}>
                Contact
            </span>

            <Modal
                style={{ color: "#000000", zIndex: 9999 }}
                show={show} onHide={handleClose}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <h3 className="pl-5 h3" style={{ border: "0" }}> Get In Touch </h3>
                </Modal.Header>
                <Modal.Body>
                    <Row  >
                        <Col sm={6} >
                            <Form className="form-class">
                                <div>
                                    <br />
                                    <center>
                                        <Field className="m-2 p-2 bg-light field border" style={{ width: "60%" }} type="name" name="name" placeholder="  name" onKeyDown={function (e) { e.stopPropagation() }} />
                                        <br />
                                        <Field className="m-2 p-2 bg-light field border" style={{ width: "60%" }} type="email" name="email" placeholder="  email" />
                                        <br />
                                        <Field as="textarea" style={{ width: "60%" }} className="m-2 p-2 bg-light field border" type="message" name="message" placeholder="  message" onKeyDown={function (e) { e.stopPropagation() }} />
                                        <br />
                                        <label className="m-2 p-3 h7">This site is protected by reCAPTCHA and the Google<br />
                                            <a href="https://policies.google.com/privacy" style={{ marginRight: "0px" }} className="text-primary h7"> Privacy Policy </a>and
                            <a href="https://policies.google.com/terms" style={{ marginRight: "0px" }} className="text-primary h7"> Terms of Service </a>apply. </label>
                                        <br />
                                        <Button size='sm' onClick={function (e) { handleSubmiting(); handleClose(); }} className="m-2 h7" variant="dark" type="submit">SEND</Button>
                                    </center>
                                </div>
                            </Form>
                        </Col>
                        <Col sm={6} >
                            <center>
                                <br />
                                <img
                                    src={world_map}
                                    width="50%"
                                    className="d-inline-block align-top p-0"
                                    alt="world_map"
                                />
                                <br />
                                <br />
                                <span className="p-1"><a href="https://g.page/ronliofficial?share" onClick={function (e) { e.stopPropagation() }}
                                    target="_blank" rel="noopener noreferrer"><MdLocationOn className="mb-1 mr-1" />302, JSK Nivaas, Marathahalli - Sarjapur Rd<br /> Bengaluru, Karnataka,
                                            India - 560015 </a> </span>
                                <br />
                                <span className="p-1">
                                    <a href='tel:+918780022355‬' onClick={function (e) { e.stopPropagation() }}>
                                        <img
                                            alt="India"
                                            width="3%"
                                            className="mb-1 mr-1"
                                            src="https://purecatamphetamine.github.io/country-flag-icons/3x2/IN.svg" /> +91 8780022355‬</a>
                                </span>
                                <br />
                                <span className="p-1">
                                    <a href='tel:+18444551393‬' onClick={function (e) { e.stopPropagation() }}>
                                        <img
                                            alt="United States"
                                            width="3%"
                                            className="mb-1 mr-1"
                                            src="https://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg" /> +1 844 455 1393‬</a>
                                </span>
                                <br />
                                <span className="p-1"><a href="mailto:support@ronli.io" onClick={function (e) { e.stopPropagation() }}><MdEmail className="mb-1 mr-1" /> support@ronli.io </a></span><br />
                            </center>

                        </Col>
                    </Row>
                </Modal.Body >
            </Modal>
        </>
    )
}
const ContactModal = withFormik({
    mapPropsToValues: () => ({ name: '', email: '' }),

    handleSubmit: (values, { setSubmitting, props }) => {

        axios.post(`https://www.ronli.io/sendMail/`, JSON.stringify(values))
            .then(res => {
                if (res.data.status === "success")
                    cogoToast.success(res.data.message)
                else
                    cogoToast.error(res.data.message)
            })
            .error(err => {
                cogoToast.error("An Error has occurred")
            })
    },

    // displayName: 'BasicForm',
})(MyForm);

export default ContactModal