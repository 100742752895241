import React from 'react';
import Row from 'react-bootstrap/Row';
import '../App.scss'

const Test = ({ property }) => {

    return (
        <Row>
            <center>
                <h2 className="h1">{property.heading}</h2>

                <h6 className="h6">{property.text}</h6>
            </center>
        </Row>
    )

}

export default Test;