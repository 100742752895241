import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Nav } from 'react-bootstrap';
import { FaInstagram, FaTwitter, FaLinkedinIn, FaFacebookF, FaBlog } from 'react-icons/fa';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import '../App.scss'
import data from '../data/data';
import Test from './Test.js';
import NavbarCustom from './Navbar.js'
import FooterCustom from './Footer.js'

class MainApp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show_footer: false,
      json_data: data.items[0],
      block_wheel: false,
      lastpos: 0,
      circle: "FaRegCircle",
      prev_bullet: 0,
      page_no: 0
    };
  }

  componentDidMount() {
    var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/5dd0464d43be710e1d1da1b7/default';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }

  handleClick = () => {
    this.setState({ show_footer: !this.state.show_footer })
  }

  changeContent = (id) => {
    this.setState({
      json_data: data.items[id]
    })
  }

  render() {
    return (
      <>
        <NavbarCustom onClick={this.changeContent} />
        <div id='stars'></div>
        <div id='stars2'></div>
        <div id='stars3'></div>
        <Container fluid >
          <Row className="content" >
            <Col md={{ span: 4, offset: 4 }} xs={{ span: 5, offset: 3 }} >
              <TransitionGroup>
                <CSSTransition
                  key={this.state.json_data.id}
                  timeout={4500}
                  classNames="slide"
                >
                  <>

                    <Test property={this.state.json_data} />

                  </>
                </CSSTransition>
              </TransitionGroup>
            </Col>
            <Col md={{ span: 1, offset: 3 }} xs={{ span: 1, offset: 2 }}>
              <div style={{ flexDirection: "column" }}>
                <Nav.Link href="https://blog.ronli.io/" target="_blank" rel="noopener noreferrer"><FaBlog className="header-icon" /></Nav.Link>
                <Nav.Link href="https://www.linkedin.com/company/ronli" target="_blank" rel="noopener noreferrer"><FaLinkedinIn className="header-icon" /></Nav.Link>
                <Nav.Link href="https://twitter.com/RonLiOfficial" target="_blank" rel="noopener noreferrer"><FaTwitter className="header-icon" /></Nav.Link>
                <Nav.Link href="https://www.facebook.com/RonLiOfficial" target="_blank" rel="noopener noreferrer"><FaFacebookF className="header-icon" /></Nav.Link>
                <Nav.Link href="https://www.instagram.com/ronliofficial/" target="_blank" rel="noopener noreferrer"><FaInstagram className="header-icon" /></Nav.Link>
              </div>
            </Col>
          </Row>
          <FooterCustom className="footer" />
        </Container>
      </>

    )
  }

}

export default MainApp;