import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';

export default class FooterCustom extends Component {
    render() {
        return (
            <>
                {/* <Navbar collapseOnSelect fixed="bottom" bg="black" variant="dark">
                    <Nav style={{ flexDirection: "column" }}>
                        <Nav.Link href="https://blog.ronli.io/" target="_blank" rel="noopener noreferrer"><FaBlog className="header-icon" /></Nav.Link>
                        <Nav.Link href="https://www.linkedin.com/company/ronli" target="_blank" rel="noopener noreferrer"><FaLinkedinIn className="header-icon" /></Nav.Link>
                        <Nav.Link href="https://twitter.com/RonLiOfficial" target="_blank" rel="noopener noreferrer"><FaTwitter className="header-icon" /></Nav.Link>
                        <Nav.Link href="https://www.facebook.com/RonLiOfficial" target="_blank" rel="noopener noreferrer"><FaFacebookF className="header-icon" /></Nav.Link>
                        <Nav.Link href="https://www.instagram.com/ronliofficial/" target="_blank" rel="noopener noreferrer"><FaInstagram className="header-icon" /></Nav.Link>
                    </Nav>
                </Navbar> */}
                <Navbar fixed="bottom" bg="black" variant="dark">
                    <Nav className="footer">
                        <Nav.Link href="#" className="header-icon" >RonLi &copy; {(new Date().getFullYear())}</Nav.Link>
                    </Nav>
                </Navbar>
            </>
        )
    }
}


