import React, { useState } from 'react';
import '../App.scss'
import { Modal, Row, Col } from 'react-bootstrap';
import career from '../resume.svg';
require("react-bootstrap/ModalHeader");

export default function CareerModal() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>
            <span onClick={handleShow}>
                Career
            </span>

            <Modal
                style={{ color: "#000000", zIndex: 9999 }}
                show={show} onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}>
                </Modal.Header>
                <Modal.Body style={{ lineHeight: "2vw" }}>
                    <Row>
                        <Col lg={6} xs={6}>
                            <div className="pl-3" style={{ position: "absolute", top: "20%" }}>
                                <h4 className="h4"> We're always Open to<br /> new Talent  </h4>
                                <span className="h7" style={{ color: 'grey' }}>Join our young and dynamic team !!<br />Email us at <a href="mailto:jobs@ronli.io" className="h7">jobs@ronli.io</a></span>
                            </div>
                            <br />
                        </Col>
                        <Col lg={6} xs={6}>
                            <img
                                src={career}
                                width="100%"
                                className="d-inline-block align-top p-0"
                                alt="career"
                            />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )

}
