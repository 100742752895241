import React, { useState } from 'react';
import '../App.scss'
import { Modal } from 'react-bootstrap';
require("react-bootstrap/ModalHeader");

export default function PrivacyPolicy() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <span href="#" onClick={handleShow}>
                Privacy
            </span>

            <Modal
                style={{ color: "#000000", height: "40em", zIndex: 9999 }}
                show={show} onHide={handleClose}
                // className="opaque"
                backdrop="static"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton id="contained-modal-title-vcenter">
                    <h5>Privacy Policy</h5>
                </Modal.Header>
                <Modal.Body style={{ 'maxHeight': 'calc(100vh - 20vh)', 'overflow': 'auto' }}>
                    <div className="ml-3 mr-3">
                        <h6>COOKIES</h6>
                            If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.

                            If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.

                            When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember Me”, your login will persist for two weeks. If you log out of your account, the login cookies will be removed.
                            <br />
                        <br />
                        <h6> ANALYTICS </h6>
                            We have Google Analytics enabled. <a href="https://support.google.com/analytics/answer/6004245">Read more</a>
                        <br />
                        <br />
                        <h6>Who we share your data with</h6>
                            Google Analytics. Refer to the previous point.
                            <br />
                        <br />
                        <h6> How long we retain your data </h6>
                            If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue. For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information.
                            <br />
                        <br />
                        <h6>What rights you have over your data</h6>
                            If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.
                            <br />
                        <br />
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <center>
                        <Button type="submit" variant="dark" onClick={handleClose}>
                            Send
                        </Button>
                    </center>
                </Modal.Footer> */}



            </Modal>
        </>
    )

}
